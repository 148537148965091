import {Injectable, OnDestroy} from "@angular/core";
import {GeneralRequestService} from "./general-request.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {BehaviorSubject, firstValueFrom, Subject} from "rxjs";
import {environment} from "../../environments/environment";
import {AppMessage} from "../models/app-message.model";
import {NotificationService} from "../features/message/notification.service";
import {FetchClassificatorsService, MessageStatuses} from "./fetch-classificators.service";
import {takeUntil} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PremiumServicesService implements OnDestroy {
  premiumRegisterIdUrl: string =
    environment.backend.baseUrl +
    environment.backend.contextPath +
    "/rest/get-premium-register-id/";
  premiumStatusUrl: string =
    environment.backend.baseUrl +
    environment.backend.contextPath +
    "/rest/get-premium-statuses/"
  private readonly destroy$ = new Subject<void>();
  allMessagesList: AppMessage[] = [];
  premiumRegisterId: number = null;
  private messageStatusesSubject = new BehaviorSubject<MessageStatuses>(null);
  messageStatuses$ = this.messageStatusesSubject.asObservable();
  hasPaidService: boolean;
  private premiumMessagesSubject = new BehaviorSubject<AppMessage[]>([]);
  premiumMessages$ = this.premiumMessagesSubject.asObservable();
  public premiumServiceEnabled: boolean // general condition if premium service as a whole is switched on or off
  public premiumServiceActiveDays: number[];
  public premiumServiceActiveHours: number[];

  constructor(
    private generalRequestService: GeneralRequestService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private classificatorService: FetchClassificatorsService,
  ) {
    this.classificatorService.messageStatuses
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.messageStatusesSubject.next(data));
    this.fetchMessages();
  }

  async checkForPremiumServices() {
    this.premiumServiceEnabled = JSON.parse(sessionStorage.getItem("PREMIUM_SERVICE_ENABLED"));
    this.hasPaidService = JSON.parse(sessionStorage.getItem("HAS_PAID_SERVICE"));
    if (this.hasPaidService === null) {
      await this.fetchPremiumServices();
      if (this.hasPaidService) {
        sessionStorage.setItem("HAS_PAID_SERVICE", "true");
        await this.fetchPremiumRegisterId();
      } else {
        // if external patient then we turn has paid service on.
        const isExternalPatient: string = JSON.parse(sessionStorage.getItem("sessionContext")).patientRole;
        if (isExternalPatient === "EXTERNAL") {
          this.hasPaidService = true;
          sessionStorage.setItem("HAS_PAID_SERVICE", "true");
        } else {
          this.hasPaidService = false;
          sessionStorage.setItem("HAS_PAID_SERVICE", "false");
        }
      }
    } else if (this.hasPaidService) {
      await this.fetchPremiumRegisterId();
    }
  }

  async fetchPremiumServices() {
    try {
      const response = await this.generalRequestService.fetchPremiumServices();

      if (!response?.items) return;

      const object = response.items[0];
      this.hasPaidService = object.hasPaidService;
    } catch (error) {
      console.error("Error fetching premium services:", error);
    }
  }

  async fetchPremiumRegisterId() {
    try {
      const response: HttpResponse<number> = await firstValueFrom(
        this.http.get<number>(this.premiumRegisterIdUrl, {
          withCredentials: true,
          observe: "response",
        }),
      );
      if (response.status === 200 && response.statusText === "OK") {
        this.premiumRegisterId = response.body;
      } else {
        console.log("Could not receive premium register id");
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  }

  async fetchPremiumStatus() {
    try {
      const response: HttpResponse<any> = await firstValueFrom(
        this.http.get<any>(this.premiumStatusUrl, {
          withCredentials: true,
          observe: "response"
        }),
      );
      if (response.status === 200) {
        if (sessionStorage.getItem("PREMIUM_SERVICE_ENABLED") == null) {
          sessionStorage.setItem("PREMIUM_SERVICE_ENABLED", response.body.premiumServiceEnabled);
        }
        if (sessionStorage.getItem("PREMIUM_SERVICE_ACTIVE_DAYS") == null) {
          sessionStorage.setItem("PREMIUM_SERVICE_ACTIVE_DAYS", response.body.premiumServiceActiveDays);
        }
        if (sessionStorage.getItem("PREMIUM_SERVICE_ACTIVE_HOURS") == null) {
          sessionStorage.setItem("PREMIUM_SERVICE_ACTIVE_HOURS", response.body.premiumServiceActiveHours);
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  }

  getPremiumStatuses() {
    this.premiumServiceEnabled = JSON.parse(sessionStorage.getItem("PREMIUM_SERVICE_ENABLED"));
    const sessionPremiumServiceDays = sessionStorage.getItem("PREMIUM_SERVICE_ACTIVE_DAYS");
    this.premiumServiceActiveDays = sessionPremiumServiceDays ? sessionPremiumServiceDays.split(',').map(Number) : [];
    const sessionPremiumServiceHours = sessionStorage.getItem("PREMIUM_SERVICE_ACTIVE_HOURS");
    this.premiumServiceActiveHours = sessionPremiumServiceHours ? sessionPremiumServiceHours.split(',').map(Number) : [];
  }

  fetchMessages() {
    this.notificationService
      .getNotifications()
      .pipe(takeUntil(this.destroy$))
      .subscribe((messages: AppMessage[]) => {
        this.allMessagesList = messages.slice();
        this.filterPremiumMessages();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isNotification() {
    return false;
  }

  private filterPremiumMessages() {
    const filteredMessages = this.allMessagesList.filter(
      (notification) =>
        notification.orderedServiceId !== null &&
        notification.orderedServiceId !== undefined &&
        !(notification.directionToPatient &&
          notification.statusCodeId === this.messageStatusesSubject.getValue()?.IN_PROGRESS),
    );
    this.premiumMessagesSubject.next(filteredMessages);
  }

  checkIsPaidServiceActive() {
    const now = new Date();
    const currentHour = now.getHours();
    const currentDay = now.getDay();
    // Use default empty arrays if premiumServiceActiveDays or premiumServiceActiveHours are undefined
    const premiumServiceActiveDay = (this.premiumServiceActiveDays ?? []).includes(currentDay);
    const premiumServiceActiveHour = (this.premiumServiceActiveHours ?? []).includes(currentHour);
    return premiumServiceActiveDay && premiumServiceActiveHour;
  }
}
